
// 菜单栏状态 滚动样式 默认展示样式
const BAR_MENU_STATUS = {
    default: 'default',
    scroll: 'scroll'
}

// 导航数据
const nav = [{
    name: "关于我们",
    id: "about",
    component: 'About',
}, {
    name: "掌阅产品",
    id: "brand",
    component: 'Brand',
}, {
    name: "版权内容",
    id: "copyright",
    component: 'Copyright',
}, {
    name: "业务矩阵",
    id: "business",
    component: 'Business',
}, {
    name: "新闻中心",
    id: "news",
    component: 'News',
}, {
    name: "投资者关系",
    id: "invest",
    component: "Invest"
}, {
    name: "社会责任",
    id: "resp",
    component: "Resp"
}, {
    name: "联系我们",
    id: "contact"
}]


// 版权内容 (后续会改为动态配置)
const copyrightList = [{
        name: "品质出版",
        desc: "掌阅自2010年起和各大出版社、图书公司广泛建立电子书的合作，力求使读者第一时间获取新书、好书，现已积累海量出版物，内容覆盖通俗小说、严肃文学、历史社科、经济管理等诸多方向，如金庸作品集、《三体》《明朝那些事儿》《百年孤独》《麦田里的守望者》等；也包括诺奖、茅奖等经典作品，如莫言作品集、《繁花》《平凡的世界》等；并曾独家首发《卧虎藏龙》《围城》《企鹅全球史》《陈寅恪的最后20年》《烧纸》、亦舒作品集等绝佳内容。此外还与国际出版巨头“企鹅兰登”公司展开全量合作，可满足读者的外语原版书阅读需求。",
        pics: [
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版1.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版2.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版3.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版4.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版5.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版6.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/品质出版7.png"
        ]
    },
    {
        name: "原创文学",
        desc: "掌阅文学成立于2015年，是掌阅自有内容的生产和孵化中心。旗下涵盖多家原创平台，包括掌阅文化、书山中文网、红薯网、趣阅小说网、有乐中文网、速更、新陌文学等。掌阅文学在为作者打造优质创作环境的同时，携手多家影视、游戏公司及视频平台，实现创作、孵化、影游漫等衍生开发一体化，并进行实景文旅、数字藏品等泛领域的积极尝试，达到内容生态的良性发展。",
        pics: [
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学1.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学2.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学3.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学4.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学5.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学6.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/原创文学7.png",
        ]
    },
    {
        name: "有声听书",
        desc: "掌阅有声筹备于2017年底，始终坚持精品化路线，内容包括版权引入以及掌阅自制原创有声小说。目前已积累80余万小时的有声内容，包括有声小说（出版、网文）、亲子儿童、相声评书、知识付费等在内的海量优质正版内容，如《围城》《三体》《人类简史》《美国陷阱》《北大徐远讲财富增值》《清华韩秀云讲经济》《趣学文言文》《干掉失眠》等品质有声内容，《延禧宫略》《蜜汁炖鱿鱼》《破冰行动》《我在未来等你》等热门影视有声书。",
        pics: [
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书1.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书2.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书3.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书4.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书5.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书6.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/有声听书7.png"
        ]
    },
    {
        name: "版权运营",
        desc: "2019年，掌阅开始致力于优质网络文学作品的电子版权及有声、影视、短剧、游戏、动漫等衍生改编版权的销售与开发，力求立足于优质IP的深度开发，以系统化、高质量的内容生产和制作，连接影视、动漫、游戏、IP衍生授权等上下游资源，持续稳定地为用户提供丰富多元的，高水准的精品内容。2021年，开始在IP短剧领域发力，旨在基于海量的内容积累和强大的内容生产能力，打破内容同质化瓶颈，以规模性、丰富性的内容，持续探索IP表达和版权衍生的更多可能。",
        pics: [
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营1.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营2.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营3.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营4.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营5.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营6.png",
            "//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/版权运营7.png"
        ]
    }
]


// 新闻列表数据 (后续会改为动态配置)
const news = {
    list: [
      {
        title: '中国“网络文学+”大会优秀作品公布，掌阅科技原创作品获提名',
        date: '2023.03.27',
        href: 'https://news.sina.com.cn/sx/2023-03-27/detail-imynhqve1863993.shtml#/'
      },
      {
        title: '第六届中国“网络文学+”大会启幕，掌阅科技融合出版成果引关注',
        date: '2023.03.27',
        href: 'http://culture.ynet.com/2023/03/27/3603813t467.html'
      },
      {
        title: '科技赋能+互动体验，第六届中国“网络文学+”大会带来沉浸式“观展”',
        date: '2023.03.24',
        href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP641db795e4b03a6b6edd8206.html'
      },
      {
        title: '掌阅科技多部融合出版网络文学作品亮相中国“网络文学+”大会',
        date: '2023.03.24',
        href: 'https://news.sina.com.cn/sx/2023-03-24/detail-imymycyf9755124.shtml'
      },
      {
        title: '终端技术发展，阅读体验变革，掌阅科技亮相中国“网络文学+”大会',
        date: '2023.03.24',
        href: 'https://qiye.chinadaily.com.cn/a/202303/24/WS641d6fb7a3102ada8b235325.html'
      },
    ],
    list_img: [{
        title: '第五届中国出版政府奖正式公布，掌阅科技再度荣获“先进出版单位”',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/32/CmQUOWHMAYKEMD7AAAAAAEGthXw192274380.png?v=g0D1ybez&t=CmQUOWHMAYI.',
        href: 'https://mp.weixin.qq.com/s/Df3lWc90FiLvmNY3Kr_Ryw',
        date: '2021年07月30日',
    }, {
        title: '掌阅与“学习强国”学习平台签订合作协议，拓展数字内容资源合作',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/32/CmQUOWHMAYKEajhsAAAAABn-VmU788060579.png?v=NtWeBWN3&t=CmQUOWHMAYI.',
        href: 'https://mp.weixin.qq.com/s/FbYPuiu_7KtNNoIaRn4Vmw',
        date: '2019年08月21日',
    }, {
        title: '企业文化3.0掌阅范',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/32/CmQUOWHMAYOEG58yAAAAAJ8KZXY603684553.png?v=9zZerIHI&t=CmQUOWHMAYM.',
        href: 'https://mp.weixin.qq.com/s/TCwAliRneeC_tEyLzGUGyA',
        date: '2021年09月08日',
    }, {
        title: '掌阅科技入选第十三届“全国文化企业30强”提名',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/32/CmQUOWHMAYKEWarlAAAAACqgvEE574037057.png?v=nKN0NAG4&t=CmQUOWHMAYI.',
        href: 'https://mp.weixin.qq.com/s/IqQRSXYucud86ReqCfkNCA',
        date: '2021年09月23日',
    }, {
        title: '秒懂掌阅范，实现组织能力正增长的关键',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/05/CmQUOGHMAYOEMtTrAAAAAGaeFj8069786180.png?v=VemVkkDn&t=CmQUOGHMAYM.',
        href: 'https://mp.weixin.qq.com/s/owq56n-N5wh6wn0uylZ77w',
        date: '2021年12月15日',
    }, {
        title: '掌阅科技LOGO升级：热情活力融合科技，探索世界更多精彩',
        img: 'https://book.img.zhangyue01.com/group61/M00/86/32/CmQUOWHMAYGEHC1fAAAAAIh_e20085495555.png?v=IpGjBYSi&t=CmQUOWHMAYE.',
        href: 'https://mp.weixin.qq.com/s/ex_eaigY-D-ilv59zMrtwA',
        date: '2021年11月25日',
    }]
}

// 投资者模块
const invest = [{
    name: '公司公告',
    href: 'http://www.sse.com.cn/assortment/stock/list/info/announcement/index.shtml?productId=603533'
}, {
    name: '股价信息',
    href: 'http://www.sse.com.cn/assortment/stock/list/info/price/index.shtml?COMPANY_CODE=603533'
}, {
    name: '投资者互动',
    href: 'http://sns.sseinfo.com/company.do?stockcode=603533'
}]

// 掌阅公益
const resp = [{
    id: 'resp1', // 跳转到公益页面的具体模块时用到
    title: "全民阅读  文化筑梦",
    apptitle: "全民阅读<br />文化筑梦",
    img: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/pic_1.f29458ba.png',
    src: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_resp1.0be93b8a.png'
}, {
    id: 'resp2',
    title: "助残扶贫  居家就业",
    apptitle: "助残扶贫<br />居家就业",
    img: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/pic_2.68e0a820.png',
    src: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_resp2.1e6eed31.png'
}, {
    id: 'resp3',
    title: "助梦远航  心源计划",
    apptitle: "助梦远航<br />心源计划",
    img: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/pic_3.c2c3f8aa.png',
    src: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_resp3.6249a2b4.png'
}, {
    id: 'resp4',
    title: "掌阅公版",
    apptitle: "掌阅公版",
    img: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/pic_4.7582d990.png',
    src: '//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_resp4.aa28f090.png'
}]

// 商务合作
const partners = [{
    module: "商业化/内容联盟SDK合作",
    name: '陈向科',
    phone: "(010)59231388（微信: jxtd606）",
    // wechat: "jxtd606",
    email: 'chenxiangke@zhangyue.com'
}, {
    module: "海外业务合作",
    name: '王晓展',
    phone: "13201708850",
    email: 'susie.wang@ireader.com'
}, 
// {
//     module: "职工电子图书馆（企业、政府单位、党建）大客户经理",
//     name: '张凯',
//     phone: "15210521093",
//     email: 'zhangkai@zhangyue.com'
// },
{
  module: "职工书屋、工会福利、电子数据库全国代理招募",
  name: '李文丽',
  phone: "18101098682(同微信)",
  email: 'liwenli@zhangyue.com'
},
 {
    module: "电子书版权合作",
    name: '谭国瑞',
    phone: "13811292759",
    email: 'tanguorui@zhangyue.com'
}, {
  module: "职工电子图书馆合作咨询",
  name: '王培让',
  phone: "13261805659（同微信）",
  email: 'wangpeirang@zhangyue.com'
}, {
  module: "iReader电子书阅读器（礼品采购/客户采购/个性定制）",
  name: '王联衡',
  phone: "13810235260",
  email: 'wanglianheng@zhangyue.com'
}, {
  module: "有声/影视剧/游戏等IP版权合作",
  list: [
    {
      name: '王小龙',
      phone: "13810234911",
      email: 'wangxiaolong@zhangyue.com'
    },
    {
      name: '赛甘若雨',
      phone: "15027869526",
      email: 'saiganruoyu@zhangyue.com'
    }
  ],
}, {
    module: "K12青少年阅读/校园业务合作",
    name: '张魔',
    phone: "17710256667",
    email: 'zhangxinrong@zhangyue.com'
}, {
    module: "电子书及有声成品接入合作",
    name: '渠诚',
    phone: "13622102681",
    email: 'qucheng@zhangyue.com'
}, {
    module: "翻译猿合作",
    list: [
      {
        name: '商务合作：李幸磊',
        phone: "18301416822",
        wechat: "xingleilee",
      },
      {
        name: '译员招募：项海鸥',
        wechat: "wdhaiou",
      }
    ]
}, {
    module: "商业业务合作",
    name: '高兵',
    phone: "(010)59231388",
    email: 'gaobing@zhangyue.com'
}, {
  module: "书城SDK合作",
  name: '霍佳顼',
  phone: "18610899969",
  email: 'huojiaxu@zhangyue.com'
}, {
    module: "媒体联系",
    phone: "010-59231341（转853）",
    email: 'pr.list@zhangyue.com'
}]

// 联系方式
const contact = [{
    type: 'service',
    name: '客服热线',
    info: '400 881 3311',
    extra: '（8:00~22:00）'
}, {
    type: 'address',
    name: '办公地址',
    info: '北京市朝阳区四惠东通惠河南岸四惠大厦2层2029E',
}, {
    type: 'phone',
    name: '总机',
    info: '010-59231388',
}, {
    type: 'fax',
    name: '传真',
    info: '010-59231388-802',
}]

// 官方微信/微博
const share = [{
    type: 'weixin',
    name: "掌阅官方微信公众号",
}, {
    type: 'weibo',
    name: "掌阅官方微博",
    href: "https://weibo.com/zhangyuesc",
}]

// 法律声明、版权声明、隐私策略
const policy = [{
    name: '法律声明',
    id: 'terms?id=contact',
}, {
    name: '版权声明',
    id: 'copyright?id=contact',
}, {
    name: '隐私策略',
    id: 'privacy?id=contact',
}]

// 备案相关
const icp = [{
    name: '京ICP证090653号',
    id: 'icp',
    img: 'icp',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/8.png',
}, {
    name: '网络出版服务许可证 （总）网出证（京）字第 143 号 ',
    id: 'ser',
    img: 'ser',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/6.png',
}, {
    name: '京网文（2023）0461-011号',
    id: 'document',
    // img: 'document',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/2.png',
}, {
    name: '京ICP备11008516号',
    id: 'icp_sec',
    href: 'https://beian.miit.gov.cn/',
}, {
    name: '京公网安备11010502030453号',
    id: 'portal',
    href: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502030453',
},
{
    name: '营业执照',
    id: 'portal',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/1.png',
},
{
    name: '广播电视节目制作经营许可证',
    id: 'portal',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/4.png',
},
{
    name: '电子出版物制作许可证',
    id: 'portal',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/5.png',
},
{
    name: '出版物经营许可证',
    id: 'portal',
    href: 'https://static-efe-front-h.zhangyuecdn.com/licenses/prod/65e15f3b4298ba00553d1a10/7.png',
}]

// 模块相应文案
const setting = {
    about: {
        title: '企业介绍',
        desc: "掌阅科技股份有限公司成立于2008年9月，专注于数字阅读，是全球领先的数字阅读平台之一。公司以“做全球最专业的阅读平台”为愿景，以“让阅读价值无处不在”为使命，以“自驱敢为 客观坦诚 简单高效 追求极致”的“掌阅范”为一致的行为倡导。公司与国内外千余家出版公司、文学网站等建立了良好合作关系，为全球150多个国家和地区的用户提供高品质的图书内容和智能化的服务体验。2017年9月21日，掌阅科技股份有限公司在上海证券交易所挂牌上市，股票代码603533。",
        desc_app: "掌阅科技股份有限公司成立于2008年9月，专注于数字阅读，是全球领先的数字阅读平台之一。<div style='height: 16px'></div>公司以“做全球最专业的阅读平台”为愿景；以“让阅读价值无处不在”为使命；以“自驱敢为 客观坦诚 简单高效 追求极致”的“掌阅范”为一致的行为倡导，与国内外上千家出版公司、文学网站等建立了良好合作关系，为全球150多个国家和地区的用户提供高品质的图书内容和智能化的服务体验。<div style='height: 16px'></div>2017年9月21日，掌阅科技股份有限公司在上海证券交易所挂牌上市，股票代码603533。",
        infoList: [{
            name: '发展历程',
            href: '/about?id=develop'
        }, {
            name: '企业文化',
            href: '/about?id=culture'
        }, {
            name: '奖项荣誉',
            href: '/about?id=honor'
        }],
        advantageList: [{
                id: 'ad1',
                name: "海量图书",
                icon: "about_book",
                desc: "经过多年积累，公司已与上千家出版公司、文学网站等建立了良好合作关系，数字内容资源丰富，品类众多，拥有海量正版优质好书，涵盖严肃出版、原创网络文学、有声读物、漫画、杂志等多种类型，对优质重磅书始终保持了较高的覆盖比例，能够满足用户各种类别、各种场景的阅读需求。主要包括名家权威推荐、大奖书系、高分书籍、畅销佳作、头部原创小说等精品内容，如：《平凡的世界》《百年孤独》《三体》《繁花》《射雕英雄传》《冰与火之歌》《哈利·波特》系列等经典作品。此外，掌阅还上线了“新时代 新经典：学习习近平新时代中国特色社会主义思想重点数字图书专栏”，具有丰富权威的党建学习资源。同时，公司大力发展“掌阅文学”内容孵化生态体系，累计签约作者数万名，通过挖掘、签约、培养、推荐、衍生增值等手段向内容市场输出优质原创内容。"
            },
            {
                id: 'ad2',
                name: "领先技术",
                icon: "about_skill",
                desc: "公司一直注重产品的研发及创新，强调用户阅读体验的产品策略始终贯穿。公司自主研发了数字阅读平台，在不断优化产品细节及性能的同时，在业内率先实现了3D仿真翻页、护眼模式等技术创新的产品应用，并在文档识别、转化、续读技术以及数字内容的精装排版等方面形成了核心技术优势。利用在内容版权资源、创作者生态、海量用户资源等领域的优势，公司积极推进AI大模型在数字阅读垂直领域的应用，推动AIGC在内容生产、营销推广、产品创新等领域深化应用。"
            },
            {
                id: 'ad3',
                name: "版权保护",
                icon: "about_copyright",
                desc: "为加强知识产权保护，特别是合作出版机构、内容供应商和作者的版权权益，为公司的版权内容审核、制作、管理提供更严格、便捷的操作系统， 掌阅自主研发搭建了版权支撑系统，建起了一个拥有海量内容版权管理能力的系统平台，该系统具备3重预警机制、16项版权风险识别、5种风险应对方案，已经过多次创新升级，逐步完善服务内容和使用体验。同时，掌阅拥有完善、透明、及时的定价系统和运营系统，并开放建立CP 联运。"
            },
            {
                id: 'ad4',
                name: "运营开发",
                icon: "about_operate",
                desc: "公司拥有丰富的用户及内容运营经验，坚持数字内容精品理念，大部分出版图书内容均采用图文并茂等富媒体的内容展现形式，充分满足用户的精品阅读体验。同时，公司通过大数据技术筛选优质内容，高效精准将优质内容触达到用户，形成了以内容挖掘和用户行为为导向的精细化且高效的数字阅读运营体系。"
            },
            {
                id: 'ad5',
                name: "多元终端",
                icon: "about_terminal",
                desc: "深耕数字阅读领域十余年来，掌阅始终在积极探索新技术在文化阅读领域的应用，打造新型数字阅读平台，已拥有涵盖产品APP、数字借阅一体机等多种终端，以及可根据客户需求定制个性化的阅读场景，打造多媒体、全平台的智能阅读空间。"
            }
        ]
    },
    brand: {
        title: '掌阅产品',
        desc: '掌阅App是由掌阅科技推出的一款电子阅读手机App，拥有出版、原创文学、有声书、漫画、杂志等海量正版内容，为读者提供优质便捷的阅读服务。公司积极探索AI大模型和掌阅App的深度融合，用AI技术赋能阅读用户读前、读中、读后等阅读全过程，提升用户阅读效率，让读书更轻松。',
        brandList: [
            // {
            //     name: "掌阅精选",
            //     icon: "zy_jx",
            //     link: "http://select.zhangyue.com/"
            // },
            {
                name: "职工电子书屋",
                icon: "zy_jx",
                link: "https://b.zhangyue.com?s=zhangyue"
            },
            {
                name: "掌阅课外书",
                icon: "zy_kw",
                link: '/zykw?id=brand'
            },
            {
                name: "掌阅书城",
                icon: "ireader",
                link: "https://www.ireader.com/"
            },
            {
                name: "掌阅动漫",
                icon: "cartoonout",
                // link: "/zybq?id=brand"
                link: "http://cm233.zhangyue.com"
            },
            {
                name: "掌阅网页版",
                icon: "ireader",
                link: "https://pc.ireader.com"
            }
        ],
        downList: [{
            name: 'Android版下载',
            href: 'https://qr.ireader.com/pve66',
            icon: 'android',
        }, {
            name: 'App Store下载',
            href: 'https://itunes.apple.com/cn/app/ireader-du-shu/id463150061?mt=8',
            icon: 'ios',
        }, {
            name: '网页版',
            href: 'https://pc.ireader.com',
            icon: 'web',
        }]
    },
    copyright: {
        title: '版权内容',
        infoList: copyrightList,
    },
    business: {
        title: '业务矩阵',
        brandList: [{
                name: "掌阅精选",
                class: "biz_jx",
                href: "https://b.zhangyue.com/",
                desc: "掌阅精选是掌阅科技旗下专注于精选优质图书并提供阅读服务的品牌，可为政企事业单位提供专业数字阅读服务，依托掌阅的精品图书资源、独创的“协同阅读”功能和定制的“阅读服务”，帮助职工养成阅读习惯，提升职工知识能力和文化素养，助力机构打造学习型组织。",
                descapp: "专注于精选优质好书并提供阅读服务，可为政企事业单位提供专业数字阅读服务。"
            },
            // {
            //     name: "iReader阅读器",
            //     class: "biz_ydq",
            //     href: "https://mall.jd.com/index-1000014841.html?from=pc",
            //     desc: "掌阅于2015年推出首款iReader电子书阅读器，目前已形成主打“轻阅读”的iReader Light系列、“为阅读发声”的iReader A6、首款彩色墨水屏阅读器iReader C6，以及集合“听·读·写·画”的iReader Smart系列等，内置更适合国人使用习惯的中文阅读系统，将硬件、软件、内容相结合，给用户高品质的阅读体验。",
            //     descapp: "更适合国人使用习惯，提供优质内容和高品质阅读体验。"
            // },
            {
                name: "掌阅国际版",
                class: "biz_int",
                desc: "掌阅国际版iReader App于2015年10月正式上线，标志着掌阅“走出去”战略正式开启。截至目前，国际版App覆盖全球150多个国家和地区，40多个“一带一路”沿线国家；向海外用户发售全球版权书籍，支持汉语（简体、繁体）、英语、西班牙语、泰语、韩语等语种。2020年掌阅国际增加了海外原创业务。",
                descapp: "覆盖150多个国家和地区，支持多语种，传播中国声音，助力中华文化“走出去”。"
            },
            {
                name: "掌阅短剧",
                class: "biz_dj",
                desc: "掌阅科技基于海量版权资源开展网络微短剧业务，并迅速实现了从剧本创作、拍摄剪辑、发行销售全链路一体化建设，打造了一批优质的微短剧内容。目前已涵盖了版权、自制、合制、付费投放、官方发行等多种形态业务，与多家制片方及技术方合作，已发行短剧数百部，涉及都市、玄幻、校园、推理悬疑、历史、言情、幻想等多种题材。",
                descapp: "基于海量版权资源开展网络微短剧业务，打造了一批优质的微短剧内容。"
            },
            // {
            //     name: "掌阅课外书",
            //     class: "biz_kws",
            //     desc: "掌阅课外书成立于2018年，专为青少年课外阅读研发，精选来自全球的数万本优质课外图书。通过分级阅读推介、兴趣部落、名家导读、社区交流等，解决青少年课外阅读多个难题，让孩子轻松享受阅读乐趣，助力成长。",
            //     descapp: "精选全球优质内容，解决课外阅读难题，让青少年快乐阅读，助力成长。"
            // },
            {
                name: "掌阅作书匠",
                class: "biz_book",
                href: "http://www.iremaker.com/",
                desc: "为保障良好的用户体验，掌阅一直严格把关电子书制作品质，先后研发了PDF版面分析工具和ePub精排版工具，形成了电子书制作的专业平台“掌阅作书匠”，极大地提升了电子书制作的质量和效率。发展至今，平台已经累计注册用户已超过15000人，月生产电子书近8000册。",
                descapp: "自主研发行业领先的专业电子书制作平台。"
            },
            {
                name: "掌阅翻译猿",
                class: "biz_transl",
                href: "http://www.transmonkey.com/",
                desc: "掌阅高度重视出海内容质量和海外用户阅读体验。为了更好地满足不断增长的海外阅读市场需求，同时有效保护网络文学作品版权，掌阅自主开发了协作式翻译平台和工具“掌阅翻译猿”，目前支持英语、法语、西班牙语、泰语等多个语种，平台注册用户超过2000人，不断提高作品翻译速度和质量，优化读者阅读体验。",
                descapp: "自主研发专业的协作式翻译平台和工具，支持英、法、西、泰等多个语种。"
            }
        ]
    },
    news: {
        title: '新闻中心',
        infoList: news,
    },
    invest: {
        title: '投资者关系',
        desc: '2017年9月21日，掌阅科技股份有限公司在上海证券交易所挂牌上市，股票代码603533。',
        investList: invest,
        contact: [{
            type: '电话',
            msg: '（8610）59236288'
        }, {
            type: '传真',
            msg: '（8610）59231388-802'
        }, {
            type: '邮箱',
            msg: 'ir@zhangyue.com'
        }]
    },
    resp: {
        title: '掌阅公益',
        desc: '掌阅成立至今，始终把"让阅读价值无处不在"作为自己的使命，积极践行企业社会责任，策划执行了多项公益活动。特别是2018年至今，先后策划推出致力于改善农村及贫困地区青少年课外阅读内容匮乏、助力提升全民阅读的“全民阅读∙文化筑梦”社会公益项目，关爱作家群体、提供保障的“心源计划”，以及助力残疾人群体实现就业、摆脱贫困的“掌阅作书匠‘助残扶贫∙居家就业’公益项目”等。<br /><br />2020年初，为助力新冠疫情防治工作，公司在掌阅APP第一时间免费开放了新型冠病毒感染防护知识及多种防疫相关的书籍，并向湖北省人民医院和武汉大学中南医院两所医院共捐助200万元，用于湖北省疫情防治工作。同时，掌阅还为湖北读者免费提供了100万份掌阅APP月卡VIP，读者可免费畅读20万册图书一个月。',
        respList: resp,
    },
    footer: {
        partners,
        contact,
        share,
        policy,
        icp,
        copyright: '2021 All Rights Reserved 掌阅科技股份有限公司 版权所有',
    },
}

export {
    BAR_MENU_STATUS,
    nav,
    setting
}