
<template>
  <div id="business" class="zy_biz">
    <swiper class="swiper" ref="mySwiper" :options="swiperOption">
      <swiper-slide
        v-for="brand in brandList"
        :key="brand.name"
        :class="brand.class"
      >
        <div class="zy_constr zy_pc">
          <div class="zy_biz_info">
            <h4>{{ brand.name }}</h4>
            <p v-html="brand.desc" />
            <a
              class="zy_biz_a"
              v-if="brand.href"
              :href="brand.href"
              target="_blank"
              >查看详情</a
            >
          </div>
        </div>
        <div class="zybiz_phone">
          <div class="zybiz_title">{{ brand.name }}</div>
          <p v-html="brand.descapp" />
          <div class="zybiz_btn">
            <a
              class="zy_biz_a"
              v-if="brand.href"
              :href="brand.href"
              target="_blank"
              >查看详情</a
            >
          </div>
          <div class="swiper-button-prev" @click="prevBtn"></div>
          <div class="swiper-button-next" @click="nextBtn"></div>
          <div class="swiper-pagination pagination2" slot="pagination"></div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="zy_biz_list">
      <div
        class="zy_biz_item"
        v-for="(item, index) in brandList"
        :key="item.name"
        v-bind:class="{ active: currentBrand.name === item.name }"
        @click="clickItem(index)"
      >
        {{ item.name }}
        <span class="zy_arrow zy_biz_detail">{{
          currentBrand.name === item.name ? "详情展示" : "详细了解"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "Business",
  data: function () {
    const { business } = setting;
    const { brandList } = business;
    const _this = this;
    return {
      business,
      brandList,
      currentBrand: brandList && brandList[0],
      swiperOption: {
        loop: true,
        speed: 1500,
        slidesPerView: 1,
        autoplay: { delay: 5000 },
        // autoplay: false,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChange: function () {
            _this.currentBrand = _this.brandList[this.activeIndex -1];
          },
        },
        pagination: {
          el: '.pagination2',
          clickable: true
        }
      },
    };
  },
  mounted() {
    this.swiper.autoplay.stop()
    window.addEventListener("scroll", this.onScroll, true);
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll() {
      const getHeight = (e) => {
        var h = 0;
        var obj = e;
        while(obj){
          h += obj.offsetTop;     // 不断累加e元素和其每个父元素的offsetTop
          obj = obj.offsetParent;
        }
        return h;
      }
      const ifInWindow = (element) => {
        var s = document.body.scrollTop || document.documentElement.scrollTop;
        var h1 = window.innerHeight;
        var off = getHeight(element);
        var h2 = element.clientHeight;
        if(s > off - h1 && s < off + h2){
          return true;
        }
        return false;
      }
      const currentElement = document.getElementById('business')
      if (currentElement && ifInWindow(currentElement)) {
        this.swiper.autoplay.start()
        window.removeEventListener("scroll", this.onScroll, true);
      }
    },
    clickItem(index) {
      this.swiper.slideToLoop(index || 0, 1500, false);
      this.swiper.autoplay.run();
      this.currentBrand = this.brandList[index];
    },
    prevBtn() {
      this.$refs.mySwiper.$swiper;
    },
    nextBtn() {
      this.$refs.mySwiper.$swiper;
    },
    pagination() {
      this.$refs.mySwiper2.$swiper;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_biz {
  height: 643px;
  position: relative;
  .swiper-slide {
    height: 643px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .biz_jx {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_jx_bg.99b1813c.png");
  }
  .biz_ydq {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_ydq_bg.4f69d123.png");
  }
  .biz_dj{
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/cb281b9a-a615-45df-839d-04534db52334.png");
  }
  .biz_int {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_int_bg.cbee46c3.png");
  }
  .biz_kws {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_kws_bg.67957aa8.png");
  }
  .biz_book {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_book_bg.cdc51c33.png");
  }
  .biz_transl {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_transl_bg.5abb2f7b.png");
  }
}

.zy_biz_info {
  padding: 56px 42px 16px 0px;
  width: 446px;

  h4 {
    margin: 44px 0px 32px;
    font-size: 28px;
    line-height: 40px;
  }

  p {
    max-height: 196px;
    overflow-y: hidden;
    margin-bottom: 16px;
  }

  .zy_biz_a {
    color: $mainActive;
    &:hover {
      color: $mainHover
    }
    &:active {
      color: $mainActived
    }
  }
}

.zy_biz_list {
  width: 992px;
  font-size: 0px;
  position: absolute;
  left: 50%;
  bottom: 70px;
  line-height: 21px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  .zy_biz_item {
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    width: 185px;
    height: 85px;
    border: 1px solid #999;
    padding: 12px 0px 12px 16px;
    vertical-align: top;
    box-sizing: border-box;
    margin-right: 16px;
    border-radius: 2px;
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      border: none;
      background-size: cover;
      color: $mainText;
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_btn_active.f6b84fd4.png");
      .zy_biz_detail {
        color: $mainText;
        &::after {
          display: none;
        }
      }
    }
  }
  .zy_biz_detail {
    font-size: 12px;
    display: block;
    margin-top: 20px;
    &::after {
      vertical-align: top;
    }
  }
}
.zybiz_phone {
  display: none;
}
@media screen and (min-width: 1441px) {
  .zy_biz {
    height: 786px;
    .swiper-slide {
      height: 786px;
    }
  }
}

.S {
  .zy_pc,
  .zy_biz_list {
    display: none;
  }
  .zybiz_phone {
    display: block;
    text-align: center;
    .zybiz_title {
      padding-top: rem(164);
      text-align: center;
      font-size: rem(60);
      color: #333;
    }
    p {
      margin: rem(80) rem(80) rem(56);
      font-size: rem(36);
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .zybiz_btn {
      a {
        display: block;
        width: rem(304);
        height: rem(82);
        text-align: center;
        margin: rem(56) auto 0;
        line-height: rem(82);
        border: rem(3) solid #999;
        -webkit-border-radius: rem(6);
        border-radius: rem(6);
        font-size: rem(36);
        color: #999;
      }
    }
  }
  .zy_biz {
    height: rem(1390);
    position: relative;
    .swiper-slide {
      height: rem(1390);
      background-size: cover;
      background-repeat: no-repeat;
    }
    .biz_jx {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zyjx.0015575c.png");
      background-repeat: no-repeat;
      background-position: top;
      background-size: 100% rem(1390);
    }
    .biz_ydq {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zyyeq.3be76670.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .biz_int {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zygj.0547d49d.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .biz_dj {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/141e138a-a2b5-4339-94c2-ae738cf6d77b.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .biz_kws {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zykw.b533bb58.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .biz_book {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zyzsj.b6160a18.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .biz_transl {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/biz_zyfyy.f8999baa.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% rem(1390);
    }
    .swiper-pagination {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: rem(56);
    }
    .swiper-pagination-bullet {
      margin: 0 rem(20);
      background: none;
      border: rem(3) solid rgba(0, 0, 0, .5);
    }
    .swiper-pagination-bullet-active {
      background: rgba(0, 0, 0, 0.5);
      border: rem(3) solid rgba(0, 0, 0, 0);
    }
    .swiper-button-prev {
      top: rem(1089);
      left: rem(116);
      width: rem(24);
      height: rem(24);
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_previcon.png") no-repeat
        center;
      -webkit-background-size: rem(24);
      background-size: rem(24);
    }
    .swiper-button-next {
      top: rem(1089);
      right: rem(116);
      width: rem(24);
      height: rem(24);
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_nexticon.png") no-repeat
        center;
      -webkit-background-size: rem(24);
      background-size: rem(24);
      z-index: 5;
    }
  }
}
</style>
